// https://github.com/Microsoft/TypeScript/issues/13923#issuecomment-2191862501
export type DeepImmutable<T> = T extends Map<infer K, infer V> ? ReadonlyMap<DeepImmutable<K>, DeepImmutable<V>> : T extends Set<infer S> ? ReadonlySet<DeepImmutable<S>> : T extends object ? { readonly [K in keyof T]: DeepImmutable<T[K]> } : T;

export const assertIdsMatchKeys = <T extends { id: string }>(obj: Record<string, T>) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (key !== value.id) {
      throw new Error(`Key "${key}" does not match id "${value.id}"`);
    }
  });
};

export const groupBy = <T, U, V extends string>(array: T[], predicate: (value: T, index?: number, array?: T[]) => V, mapper: (value: T) => U) =>
  array.reduce<Record<V, U[]>>(
    (acc, value, index, array) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (acc[predicate(value, index, array)] ??= []).push(mapper(value));
      return acc;
    },
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    {} as unknown as Record<V, U[]>,
  );
