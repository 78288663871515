import { z } from 'zod';
import { assertIdsMatchKeys, groupBy } from './utils';

export const permissionGroups = {
  users: { title: `Users` },
  jobs: { title: `Jobs` },
} as const satisfies Record<string, { title: string }>;
export type PermissionGroup = typeof permissionGroups[keyof typeof permissionGroups];
export type PermissionGroupId = keyof typeof permissionGroups;
export const permissionGroupIds: readonly PermissionGroupId[] = Object.keys(permissionGroups) as PermissionGroupId[];

export const permissions = {
  [`view:job`]: { id: `view:job`, title: `View Job`, description: `View an existing job`, group: `jobs` },
  [`create:job`]: { id: `create:job`, title: `Create Job`, description: `Create a new job`, group: `jobs` },
  [`update:job`]: { id: `update:job`, title: `Update Job`, description: `Edit/update an existing job`, group: `jobs` },
  [`delete:job`]: { id: `delete:job`, title: `Delete Job`, description: `Delete an existing job`, group: `jobs` },
  [`view:user`]: { id: `view:user`, title: `View User`, description: `View an existing user`, group: `users` },
  [`create:user`]: { id: `create:user`, title: `Create User`, description: `Create a new user`, group: `users` },
  [`update:user`]: { id: `update:user`, title: `Update User`, description: `Edit/update an existing user`, group: `users` },
  [`delete:user`]: { id: `delete:user`, title: `Delete User`, description: `Delete an existing user`, group: `users` },
} as const satisfies Record<string, { id: string, title: string, description: string, group: PermissionGroupId }>;

assertIdsMatchKeys(permissions);

export type Permission = typeof permissions[keyof typeof permissions];
export type PermissionId = keyof typeof permissions;
export const permissionIds: readonly PermissionId[] = Object.keys(permissions) as PermissionId[];
export const isPermissionId = (value: unknown) => permissionIds.includes(value as PermissionId);
export const zodPermissionId = z.custom<PermissionId>(isPermissionId);

export const permisionOptions = Object.entries(groupBy(Object.values(permissions), p => p.group, p => ({ value: p.id, label: p.title })))
  .map(([group, items]) => ({ group, items }));
