import { createFileRoute } from '@tanstack/react-router';
import JobList from '../../../components/JobList';

const JobListRouteComponent = () => {
  return <JobList />;
};

export const Route = createFileRoute(`/_normal-layout/jobs/`)({
  component: JobListRouteComponent,
});
