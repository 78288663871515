import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormSubmitHandler, useForm, useWatch } from 'react-hook-form';
import { TextInput } from 'react-hook-form-mantine';
import { Button, Group, Loader, Transition } from '@mantine/core';
// import { Job } from '../../db';
import { requiredString, showSavedNotification, showSavingErrorNotification, useFormDefaultOptions } from './utils';
import { z } from 'zod';
import { useNavigate } from '@tanstack/react-router';
import { db } from '../../db';

const schema = z.object({
  title: requiredString(),
  description: z.string().optional().default(``),
  hours: z.number().default(0),
});

type Schema = z.output<typeof schema>;

interface JobFormProps {
  jobId?: string
}

export const JobForm = (props: JobFormProps) => {
  const { jobId } = props;
  const navigate = useNavigate();
  const isNew = jobId === undefined;

  const { control, formState, getValues } = useForm<Schema>({
    ...useFormDefaultOptions,
    resolver: zodResolver(schema),
    defaultValues: () => db.job.getDataOrDefault(jobId, { title: ``, description: ``, hours: 0 }),
  });
  useWatch({ control, name: [`title`] });

  const values = getValues();

  const showDescription = !!values.title && formState.errors.title === undefined;

  const onSubmit: FormSubmitHandler<Schema> = (payload) => {
    console.log(payload);
    const ref = db.job.insertOrSet(payload.data, jobId);
    if (isNew) void navigate({ to: `/jobs/${ref.id}/view` });

    showSavedNotification();
  };

  const onError = (error: unknown) => {
    console.error(error);
    showSavingErrorNotification();
  };

  console.log(`Form rendering`, formState);

  if (formState.isLoading) return <Loader size="xl" />;

  return (
    <Form control={control} onSubmit={onSubmit} onError={onError}>
      <TextInput name="title" control={control} label="Title" />
      <Transition mounted={showDescription} transition="scale-y" duration={1000} timingFunction="ease">
        {styles => <TextInput name="description" control={control} label="Description" style={styles} />}
      </Transition>
      <Group mt="md">
        <Button type="submit">Submit</Button>
      </Group>
    </Form>
  );
};
