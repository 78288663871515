import { Tenant, TenantDbModel, tenantModelTools } from './tenant';
import { User, UserDbModel, userModelTools } from './user';
import { jobModelTools, Job, JobDbModel } from './job';
import { AppModelForWrite, ModelTools } from './common';

export type { Tenant, TenantDbModel } from './tenant';
export type { User, UserDbModel, UserSnapshot } from './user';
export type { Job, JobDbModel } from './job';

export type JobForWrite = AppModelForWrite<Job>;

export const db: {
  tenant: ModelTools<Tenant, TenantDbModel>
  user: ModelTools<User, UserDbModel>
  job: ModelTools<Job, JobDbModel>
} = {
  tenant: tenantModelTools,
  user: userModelTools,
  job: jobModelTools,
} as const;

export type ModelName = keyof typeof db;
