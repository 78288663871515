import React from 'react';
import { Container, Group, Text, ActionIcon, Divider, Select as MantineSelect } from '@mantine/core';
import { PermissionGroupId, permissionGroups, PermissionId, permissions } from '../../permissions';
import { CancelIcon } from '../icons/icons';
import { groupBy } from '../../utils';
import { Tenant } from '../../db';

interface RolePermissionsProps {
  role: Tenant[`roles`][number]
  roleIndex: number
  onAddPermission: (permissionId: PermissionId | null) => void
  onRemovePermission: (permissionId: PermissionId) => void
}

export const RolePermissionsForm = ({ role, onRemovePermission, onAddPermission }: RolePermissionsProps) => {
  const permissionOptions = Object.entries(
    groupBy(
      Object.values(permissions).filter(p => !role.permissionIds.includes(p.id)),
      (p): PermissionGroupId => p.group,
      p => ({ value: p.id, label: p.title }),
    ),
  ).map(([groupId, items]) => ({ group: permissionGroups[groupId as PermissionGroupId].title, items }));

  return (
    <>
      {role.permissionIds.length === 0 && <Text c="dimmed">No permissions for this role yet 😢</Text>}
      {role.permissionIds.map((permissionId) => {
        const permission = permissions[permissionId];
        return (
          <React.Fragment key={permissionId}>
            <Container p={0} py="sm">
              <Group justify="space-between">
                <div>
                  <Text>{permission.title}</Text>
                  <Text c="dimmed">{permission.description}</Text>
                </div>
                <ActionIcon onClick={() => onRemovePermission(permissionId)} color="red" variant="subtle">
                  <CancelIcon />
                </ActionIcon>
              </Group>
            </Container>
            <Divider />
          </React.Fragment>
        );
      })}
      <Group mt="md">
        <Text>Add permission: </Text>
        <MantineSelect data={permissionOptions} value={null} onChange={permissionId => onAddPermission(permissionId as PermissionId | null)} />
      </Group>
    </>
  );
};
