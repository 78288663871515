import { initializeApp, FirebaseApp, FirebaseOptions } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { CACHE_SIZE_UNLIMITED, connectFirestoreEmulator, initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { envVars } from './env';

const firebaseConfig: FirebaseOptions = {
  apiKey: `AIzaSyDVScp9ZlHDukSFMzQi-nz8UNdTrpXsq0A`,
  authDomain: `slick-systems-a863d.firebaseapp.com`,
  projectId: `slick-systems-a863d`,
  storageBucket: `slick-systems-a863d.appspot.com`,
  messagingSenderId: `23748833169`,
  appId: `1:23748833169:web:471c967bbc950aada0b430`,
  measurementId: `G-YLC0PLM0DN`,
};

export const firebase: FirebaseApp = initializeApp(firebaseConfig);
export const firestore = initializeFirestore(firebase, {
  ignoreUndefinedProperties: true,
  localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager(), cacheSizeBytes: CACHE_SIZE_UNLIMITED }),
});

export const firebaseAuth = getAuth(firebase);
export const firebaseStorage = getStorage(firebase);
export const firebaseFunctions = getFunctions(firebase);

if (envVars.CONNECT_TO_FIREBASE_EMULATORS) {
  connectAuthEmulator(firebaseAuth, `http://127.0.0.1:9099`);
  connectFirestoreEmulator(firestore, `127.0.0.1`, 8080);
  connectStorageEmulator(firebaseStorage, `127.0.0.1`, 9199);
  connectFunctionsEmulator(firebaseFunctions, `127.0.0.1`, 5001);
}
