import { createFileRoute } from '@tanstack/react-router';
import { UserForm } from '../../../components/forms/UserForm';

const UserEditRouteComponent = () => {
  const { userId } = Route.useParams();
  return <UserForm userId={userId} />;
};

export const Route = createFileRoute(`/_normal-layout/users/$userId/edit`)({
  component: UserEditRouteComponent,
});
