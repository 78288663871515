import { createFileRoute } from '@tanstack/react-router';
import { UserList } from '../../../components/UserList';

const UserListRouteComponent = () => {
  return <UserList />;
};

export const Route = createFileRoute(`/_normal-layout/users/`)({
  component: UserListRouteComponent,
});
