import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormSubmitHandler, useForm, useWatch } from 'react-hook-form';
import { TextInput } from 'react-hook-form-mantine';
import { Button, Group, Loader, Select as MantineSelect, Container, Divider } from '@mantine/core';
// import { Tenant } from '../../db';
import { requiredString, showSavedNotification, showSavingErrorNotification, useFormDefaultOptions } from './utils';
import { z } from 'zod';
import { useNavigate } from '@tanstack/react-router';
import { db } from '../../db';
import { PermissionId, isPermissionId } from '../../permissions';
import { DeepImmutable } from '../../utils';
import { onSnapshot, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { TenantId, tenantIds } from '../../db/tenant';
import { RolesForm } from './RolesForm';
import { PageTitle, SectionHeading } from '../Typography';

const schema = z.object({
  title: requiredString(),
  roles: z.array(
    z.object({
      id: requiredString(),
      title: requiredString(),
      description: z.string().optional(),
      permissionIds: z.array(z.custom<PermissionId>(isPermissionId)),
    }),
  ),
});

export type TenantFormSchema = DeepImmutable<z.output<typeof schema>>;

interface TenantFormProps {
  tenantId?: string
}

export const TenantForm = (props: TenantFormProps) => {
  const { tenantId } = props;
  const navigate = useNavigate();
  const [unusedTenantIds, setUnusedTenantIds] = useState<TenantId[] | undefined>(undefined);
  const [newTenantId, setNewTenantId] = useState<TenantId | undefined>(undefined);
  const isNew = tenantId === undefined;

  const { control, formState, getValues, setValue } = useForm<TenantFormSchema>({
    ...useFormDefaultOptions,
    resolver: zodResolver(schema),
    defaultValues: () => db.tenant.getDataOrDefault(tenantId, { title: ``, roles: [] }),
  });
  useWatch({ control, name: [`title`] });
  useWatch({ control, name: [`roles`] });

  const values = getValues();

  useEffect(() => {
    if (tenantId !== undefined) return;
    return onSnapshot(query(db.tenant.s()), snap => setUnusedTenantIds(tenantIds.filter(id => !snap.docs.some(doc => doc.id === id))));
  }, [tenantId]);

  const onSubmit: FormSubmitHandler<TenantFormSchema> = (payload) => {
    if (!newTenantId && isNew) throw new Error(`No tenantId`);
    const ref = db.tenant.insertOrSet(payload.data, isNew ? newTenantId : tenantId);
    if (tenantId === undefined) void navigate({ to: `/tenants/${ref.id}/view` });

    showSavedNotification();
  };

  const onError = (error: unknown) => {
    console.error(error);
    showSavingErrorNotification();
  };

  if (formState.isLoading) return <Loader size="lg" />;

  return (
    <Container maw={600}>
      <PageTitle>{isNew ? `New Tenant: ${newTenantId ?? `?`}` : `Edit Tenant: ${values.title}`}</PageTitle>
      <Form control={control} onSubmit={onSubmit} onError={onError}>
        {unusedTenantIds && <MantineSelect name="tenantId" label="New Tenant ID" data={unusedTenantIds.map(id => ({ value: id, label: id }))} onChange={id => setNewTenantId(id as TenantId)} mb="md" />}

        <TextInput name="title" control={control} label="Title" />

        <SectionHeading mb="xs">Roles & Permissions</SectionHeading>
        <RolesForm control={control} values={values} setValue={setValue} />

        <Divider mt="xl" />
        <Group mt="md" justify="flex-end">
          <Button type="submit" color="green">
            Save
          </Button>
        </Group>
      </Form>
    </Container>
  );
};
