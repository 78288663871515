import * as Sentry from '@sentry/react';

addEventListener(`unhandledrejection`, (event) => { console.error(event); });
addEventListener(`error`, (event) => { console.error(event); });

Sentry.init({
  dsn: `https://e6077ff4e7c2e76583a78f967f815dc8@o4507935280070656.ingest.us.sentry.io/4507935283412992`,
  integrations: [
    Sentry.globalHandlersIntegration({
      onerror: false,
      onunhandledrejection: false,
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [`localhost`, /^https:\/\/[a-z-]*\.slick\.systems/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
