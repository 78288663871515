import { Card, Loader, Text } from '@mantine/core';
import { onSnapshot } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { Job, db } from '../db';
import { TsrNavButton } from './TsrNavLink';

interface JobViewFullProps {
  jobId: string
}

export const JobViewFull = ({ jobId }: JobViewFullProps) => {
  const [job, setJob] = useState<Job | undefined>();

  useEffect(() => onSnapshot(db.job.ref(jobId), snap => setJob(snap.data())), [jobId]);

  if (!job) return <Loader size="xl" />;

  return (
    <Card padding="lg" withBorder>
      <Card.Section inheritPadding>
        <Text size="lg">{job.title}</Text>
      </Card.Section>
      <Card.Section>
        <Text>{job.description}</Text>
      </Card.Section>
      <Card.Section>
        <TsrNavButton color="blue" to="/jobs/$jobId/edit" params={{ jobId }}>
          Edit
        </TsrNavButton>
      </Card.Section>
    </Card>
  );
};
