import { z } from 'zod';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { appBaseFieldsToDb, baseModelSchemaParts, DeepImmutableExceptDates, buildTools } from './common';

const paths = {
  'jobs': `jobs`,
  'users/$userId/activeJobs': (userId: string) => `users/${userId}/activeJobs`,
};

const schema = z.object({
  ...baseModelSchemaParts,
  title: z.string(),
  description: z.string().default(`None provided`),
  hours: z.number().default(0),
});

type DbModel = z.input<typeof schema>;
type AppModel = DeepImmutableExceptDates<z.output<typeof schema>>;

const converter = {
  toFirestore: (data: AppModel): DbModel => ({ ...data, ...appBaseFieldsToDb(data) }),
  fromFirestore: (snap: QueryDocumentSnapshot): AppModel => schema.parse({ ...snap.data(), id: snap.id }),
};

export type { AppModel as Job, DbModel as JobDbModel };

export const jobModelTools = {
  ...buildTools<AppModel, DbModel>(converter, paths.jobs),
  schema,
} as const;
