import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Stack } from '@mantine/core';
import classes from './_centered-layout.module.css';

export const Route = createFileRoute(`/_centered-layout`)({
  component: () => {
    return (
      <Stack align="center" justify="center" mih="100vh" className={classes[`wrapper`]!}>
        <Outlet />
      </Stack>
    );
  },
});
