import { createFileRoute } from '@tanstack/react-router';
import { UserForm } from '../../../components/forms/UserForm';

const UserNewRouteComponent = () => {
  return <UserForm />;
};

export const Route = createFileRoute(`/_normal-layout/users/new`)({
  component: UserNewRouteComponent,
});
