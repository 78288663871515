import { Menu, ActionIcon, useMantineColorScheme } from '@mantine/core';
import { CalendarIcon, LightDarkIcon, LoginIcon, LogoutIcon, SettingsIcon, TimeLogIcon, UserIcon } from './icons/icons';
import { firebaseAuth } from '../firebase';
import { useNavigate } from '@tanstack/react-router';
import { TsrMenuItem } from './TsrNavLink';

export const MyMenu = () => {
  const navigate = useNavigate();
  const { toggleColorScheme, colorScheme } = useMantineColorScheme();

  const handleLogout = () => {
    firebaseAuth.signOut()
      .then(() => navigate({ to: `/` }))
      .catch((error: unknown) => console.error(error));
  };

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <ActionIcon variant="default">
          <UserIcon />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Application</Menu.Label>
        <Menu.Item leftSection={<SettingsIcon />}>My Profile</Menu.Item>
        <Menu.Item onClick={toggleColorScheme} leftSection={<LightDarkIcon />}>
          {colorScheme === `dark` ? `Light Theme` : `Dark Theme`}
        </Menu.Item>
        <Menu.Item leftSection={<TimeLogIcon />}>My Time Log</Menu.Item>
        <Menu.Item leftSection={<CalendarIcon />}>My Calendar</Menu.Item>
        <Menu.Divider />
        <TsrMenuItem leftSection={<LoginIcon />} to="/auth/login">Login</TsrMenuItem>
        <Menu.Item leftSection={<LogoutIcon />} onClick={handleLogout}>Logout</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
