import { createFileRoute, Outlet } from '@tanstack/react-router';
import { AppShell, Group, Burger, Image, alpha, ActionIcon, useMantineColorScheme, Drawer, Space } from '@mantine/core';
import { useDisclosure, useHeadroom } from '@mantine/hooks';
import slickLogoPortrait from '../assets/logo-portrait-blue-orange.png';
import { MyMenu } from '../components/MyMenu';
import { MainMenu } from '../components/MainMenu';

import styles from './_normal-layout.module.css';

import diamondPlate from '../assets/diamond-plate.jpg';
import { LightDarkIcon } from '../components/icons/icons';
import { Footer } from '../components/Footer';

const NormalLayout = () => {
  const { toggleColorScheme } = useMantineColorScheme();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure();
  const pinned = useHeadroom({ fixedAt: 120 });

  const diamondPlateBg = `linear-gradient(${alpha(`var(--mantine-color-body)`, 0.9)}, ${alpha(`var(--mantine-color-body)`, 0.9)}), url(${diamondPlate})`;

  return (
    <AppShell
      header={{ height: 60, collapsed: !pinned }}
      navbar={{
        width: 52,
        breakpoint: `sm`,
        collapsed: { mobile: true, desktop: false },
      }}
      padding="md"
    >
      <AppShell.Header bg={diamondPlateBg} bgsz="cover">
        <Group h="100%" px="md" justify="space-between">
          <Group>
            <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" size="sm" />
          </Group>
          <Group>
            <Image src={slickLogoPortrait} alt="Slick Systems logo" h={40} />
          </Group>
          <Group>
            <ActionIcon onClick={toggleColorScheme} variant="default">
              <LightDarkIcon />
            </ActionIcon>
            <MyMenu />
          </Group>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p={0} bg={diamondPlateBg} bgsz="cover" className={styles[`navbar`]!}>
        <MainMenu />
      </AppShell.Navbar>
      <Drawer opened={drawerOpened} onClose={closeDrawer} onClick={closeDrawer} overlayProps={{ backgroundOpacity: 0.5, blur: 4 }} withCloseButton={false} size="xs" padding={0}>
        <Space h="lg" />
        <MainMenu />
      </Drawer>
      <AppShell.Main className={styles[`main`]!}>
        <Outlet />
        <Footer />
      </AppShell.Main>
    </AppShell>
  );
};

export const Route = createFileRoute(`/_normal-layout`)({
  component: NormalLayout,
});
