import { notifications } from '@mantine/notifications';
import { CancelIcon, CheckIcon } from '../icons/icons';
import { z } from 'zod';

export const showSavedNotification = (msg?: string) => {
  notifications.show({
    icon: <CheckIcon />,
    color: `green`,
    title: `Saved!`,
    message: msg,
  });
};

export const showSavingErrorNotification = (msg?: string) => {
  notifications.show({
    icon: <CancelIcon />,
    color: `red`,
    title: `Error`,
    message: msg ?? `An error occurred while saving. Please try again or contact support.`,
  });
};

export const useFormDefaultOptions = {
  mode: `onBlur`,
  criteriaMode: `all`,
  resetOptions: {
    keepDefaultValues: true,
    keepDirtyValues: true,
    keepErrors: true,
  },
} as const;

export const requiredString = (message = `This is a required field`) => z.string().trim().min(1, message);
