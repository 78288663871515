import { z } from 'zod';

const envSchema = z.object({
  ENV: z.enum([`local`, `development`, `production`]),
  CONNECT_TO_FIREBASE_EMULATORS: z.enum([`true`, `false`]).default(`false`).transform(value => value === `true`),
});

export type EnvVars = Readonly<z.output<typeof envSchema>>;

const envCandidate: Record<keyof EnvVars, string | undefined> = {
  ENV: import.meta.env[`PUBLIC__ENV` satisfies `PUBLIC__${keyof EnvVars}`] as string | undefined,
  CONNECT_TO_FIREBASE_EMULATORS: import.meta.env[`PUBLIC__CONNECT_TO_FIREBASE_EMULATORS` satisfies `PUBLIC__${keyof EnvVars}`] as string | undefined,
} as const;

export const envVars: EnvVars = envSchema.parse(envCandidate);
