import { createFileRoute } from '@tanstack/react-router';
import { JobForm } from '../../../components/forms/JobForm';

const JobNewRouteComponent = () => {
  return <JobForm />;
};

export const Route = createFileRoute(`/_normal-layout/jobs/new`)({
  component: JobNewRouteComponent,
});
