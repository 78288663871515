import { createFileRoute } from '@tanstack/react-router';
import { JobViewFull } from '../../../components/JobViewFull';

const JobRouteComponent = () => {
  const { jobId } = Route.useParams();
  return <JobViewFull jobId={jobId} />;
};

export const Route = createFileRoute(`/_normal-layout/jobs/$jobId/view`)({
  component: JobRouteComponent,
});
