import { Accordion, ActionIcon, Button, Group, Space, Text, Title } from '@mantine/core';
import { Control, UseFormSetValue } from 'react-hook-form';
import { Textarea, TextInput } from 'react-hook-form-mantine';
import { RolePermissionsForm } from './RolePermissionsForm';
import { generateId } from '../../db/common';
import { PermissionId } from '../../permissions';
import type { TenantFormSchema } from './TenantForm';
import { AddIcon, CancelIcon } from '../icons/icons';
import { modals } from '@mantine/modals';
import { MouseEvent } from 'react';

interface RoleFormProps {
  control: Control<TenantFormSchema>
  values: TenantFormSchema
  setValue: UseFormSetValue<TenantFormSchema>
}

export const RolesForm = (props: RoleFormProps) => {
  const { control, values, setValue } = props;

  const handleAddRole = () => {
    const newRole = {
      id: generateId(),
      title: `New Role`,
      description: `Describe the role here`,
      permissionIds: [],
    };
    setValue(`roles.${values.roles.length}`, newRole);
  };

  const handleAddAddPermission = (roleIndex: number, permissionId: PermissionId | null) => {
    if (permissionId === null) return;
    const role = values.roles[roleIndex];
    if (!role) throw new Error(`Role not found when adding permission`);
    setValue(`roles.${roleIndex}.permissionIds`, [...role.permissionIds, permissionId]);
  };

  const handleRemovePermission = (roleIndex: number, permissionId: PermissionId) => {
    const role = values.roles[roleIndex];
    if (!role) throw new Error(`Role not found when removing permission`);
    setValue(
      `roles.${roleIndex}.permissionIds`,
      role.permissionIds.filter(id => id !== permissionId),
    );
  };

  const handleDeleteRole = (event: MouseEvent, roleIndex: number) => {
    event.stopPropagation();
    openConfirmDeleteRoleModal(roleIndex);
  };

  const openConfirmDeleteRoleModal = (roleIndex: number) => {
    const role = values.roles[roleIndex];
    modals.openConfirmModal({
      title: `Delete "${role?.title}" Role?`,
      children: <Text size="sm">Are you sure you want to delete the "{role?.title}" role for this tenant?</Text>,
      labels: { confirm: `Delete`, cancel: `Cancel` },
      confirmProps: { color: `red` },
      onCancel: () => console.log(`Cancel`),
      onConfirm: () => deleteRole(roleIndex),
    });
  };

  const deleteRole = (roleIndex: number) => {
    setValue(
      `roles`,
      values.roles.filter((_, index) => index !== roleIndex),
    );
  };

  return (
    <>
      <Accordion variant="contained" radius="xs" chevronPosition="left" multiple>
        {values.roles.map((role, roleIndex) => {
          return (
            <Accordion.Item key={role.id} value={role.id}>
              <Accordion.Control>
                <Group>
                  <TextInput name={`roles.${roleIndex}.title`} onClick={(event: React.MouseEvent) => event.stopPropagation()} size="md" p={0} control={control} flex={1} />
                  <ActionIcon onClick={(event: MouseEvent) => handleDeleteRole(event, roleIndex)} color="red" variant="subtle" component="span">
                    <CancelIcon />
                  </ActionIcon>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <Textarea name={`roles.${roleIndex}.description`} control={control} size="md" p={0} />
                <Title order={3} mt="md">
                  Permissions
                </Title>
                <RolePermissionsForm role={role} roleIndex={roleIndex} onRemovePermission={pId => handleRemovePermission(roleIndex, pId)} onAddPermission={pId => handleAddAddPermission(roleIndex, pId)} />
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <Group mt="md" justify="flex-end">
        <Button onClick={handleAddRole} variant="light">
          <AddIcon />
          <Space w="sm" /> Add Role
        </Button>
      </Group>
    </>
  );
};
