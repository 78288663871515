import { z } from 'zod';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { appBaseFieldsToDb, baseModelSchemaParts, DeepImmutableExceptDates, buildTools } from './common';
import { tenantIds } from './tenant';
import { zodPermissionId } from '../permissions';

const paths = {
  users: `users`,
};

const schema = z.object({
  ...baseModelSchemaParts,
  email: z.string().email(),
  displayName: z.string(),
  roleIds: z.record(z.enum(tenantIds), z.string()),
  permissionIds: z.record(z.enum(tenantIds), z.array(zodPermissionId)),
});

type DbModel = DeepImmutableExceptDates<z.input<typeof schema>>;
type AppModel = DeepImmutableExceptDates<z.output<typeof schema>>;
type DocSnapshot = QueryDocumentSnapshot<AppModel, DbModel>;

const converter = {
  toFirestore: (data: AppModel): DbModel => ({ ...data, ...appBaseFieldsToDb(data) }),
  fromFirestore: (snap: QueryDocumentSnapshot): AppModel => schema.parse({ ...snap.data(), id: snap.id }),
};

export type { AppModel as User, DbModel as UserDbModel, DocSnapshot as UserSnapshot };

export { schema as userSchema };

export const userModelTools = {
  ...buildTools<AppModel, DbModel>(converter, paths.users, { isCrossTenant: true }),
} as const;
