import { createFileRoute } from '@tanstack/react-router';
import { TenantForm } from '../../../components/forms/TenantForm';

const TenantEditRouteComponent = () => {
  const { tenantId } = Route.useParams();
  return (
    <>
      <TenantForm tenantId={tenantId} />
    </>
  );
};

export const Route = createFileRoute(`/_normal-layout/tenants/$tenantId/edit`)({
  component: TenantEditRouteComponent,
});
