import { Group, Text } from '@mantine/core';

export const Footer = () => {
  return (
    <Group justify="center" align="center" bg="rgba(0,0,0,.2)" style={{ margin: -16 }} p="sm">
      <Text c="dimmed" size="sm">
        &copy; {new Date().getFullYear()} Slick Systems
      </Text>
    </Group>
  );
};
