import { z } from 'zod';
import { DeepImmutable } from '../utils';
import dayjs from 'dayjs';
import { collection, doc, DocumentData, DocumentReference, getDoc, PartialWithFieldValue, QueryDocumentSnapshot, serverTimestamp, setDoc, SetOptions, Timestamp, UpdateData, updateDoc, WithFieldValue } from 'firebase/firestore';
import { customAlphabet } from 'nanoid';
import { firestore } from '../firebase';
import { TenantId } from './tenant';

export const generateId = customAlphabet(`123456789abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ`, 9);

export type DeepImmutableExceptDates<T extends MutableAppBaseModel | DbBaseModel> = DeepImmutable<Omit<T, `createdAt` | `updatedAt`>> & Readonly<Pick<T, `createdAt` | `updatedAt`>>;

// From DbModel to AppModel
export const baseModelSchemaParts = {
  id: z.string(),
  createdAt: z.custom<Timestamp | undefined>(() => true).transform(d => d ? dayjs(d.toDate()) : dayjs()),
  updatedAt: z.custom<Timestamp | undefined>(() => true).transform(d => d ? dayjs(d.toDate()) : dayjs()),
} as const;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const baseModelSchema = z.object(baseModelSchemaParts);
type DbBaseModel = z.input<typeof baseModelSchema>;
type MutableAppBaseModel = z.output<typeof baseModelSchema>;
export type AppBaseModel = DeepImmutableExceptDates<MutableAppBaseModel>;

export type AppModelForWrite<AppModel> = Omit<AppModel, keyof MutableAppBaseModel>;

export const appBaseFieldsToDb = (data: AppBaseModel): Required<DbBaseModel> => {
  return data as unknown as Required<DbBaseModel>;
};

export const buildTools = <AppModel extends AppBaseModel, DbModel extends DocumentData>(
  converter: {
    toFirestore: (data: AppModel) => DbModel
    fromFirestore: (snap: QueryDocumentSnapshot) => AppModel
  },
  defaultPath: string | undefined,
  { isCrossTenant = false }: { isCrossTenant?: boolean } = {},
) => {
  const tenant: TenantId = `acdc-welding`;

  type DocRef = DocumentReference<AppModel, DbModel>;
  // type CollectionRef = CollectionReference<AppModel, DbModel>;
  type AppModelWithoutBase = AppModelForWrite<AppModel>;
  type DocRefOrId = DocRef | string;

  const getCollectionPath = (path?: string) => {
    if (path === undefined && defaultPath === undefined) throw new Error(`Neigher path nor defaultPath is defined`);
    let fullPath = ``;
    if (!isCrossTenant) fullPath += `tenants/${tenant}/`;
    if (path !== undefined) fullPath += path;
    else if (defaultPath !== undefined) fullPath += defaultPath;
    return fullPath;
  };

  const docRef = (docRefOrId: DocRefOrId, path?: string) => {
    const collectionPath = getCollectionPath(path);
    const fullPath = typeof docRefOrId === `string` ? `${collectionPath}/${docRefOrId}` : docRefOrId.path;
    return doc(firestore, fullPath).withConverter(converter);
  };

  const collectionRef = (path?: string) => {
    return collection(firestore, getCollectionPath(path)).withConverter(converter);
  };

  const set = (idOrRef: DocRefOrId, data: AppModelWithoutBase, path?: string): DocRef => {
    const ref = docRef(idOrRef, path);
    void setDoc(ref, { ...data, updatedAt: serverTimestamp(), createdAt: undefined, id: undefined } as WithFieldValue<AppModel>);
    return ref;
  };

  const setPartial = (idOrRef: DocRefOrId, data: PartialWithFieldValue<AppModel>, options?: SetOptions): DocRef => {
    const ref = docRef(idOrRef);
    void setDoc(ref, { ...data, updatedAt: serverTimestamp(), createdAt: undefined, id: undefined }, options = { merge: true, ...options });
    return ref;
  };

  const insert = (data: AppModelWithoutBase, path?: string) => {
    const ref = docRef(generateId(), path);

    void setDoc(ref, {
      ...data,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      id: undefined,
    } as WithFieldValue<AppModel>);
    return ref;
  };

  const insertOrSet = (data: AppModelWithoutBase, idOrRef?: DocRefOrId, path?: string) => {
    if (idOrRef === undefined) return insert(data, path);
    return set(idOrRef, data, path);
  };

  const update = (idOrRef: DocRefOrId, data: UpdateData<DbModel>, path?: string) => {
    const ref = docRef(idOrRef, path);
    return updateDoc(ref, { ...data, updatedAt: serverTimestamp(), createdAt: undefined, id: undefined });
  };

  const get = async (idOrRef: DocRefOrId, path?: string) => {
    const ref = docRef(idOrRef, path);
    return getDoc(ref);
  };

  const getDataOrDefault = async (idOrRef: DocRefOrId | undefined, defaults: AppModelWithoutBase, path?: string): Promise<AppModelForWrite<AppModel>> => {
    if (idOrRef === undefined) return defaults;
    const doc = await get(idOrRef, path);
    if (doc.exists()) return doc.data();
    return defaults;
  };

  return {
    ref: docRef,
    s: collectionRef,
    insert,
    insertOrSet,
    update,
    set,
    setPartial,
    get,
    getDataOrDefault,
  } as const;
};

export interface ModelTools<AppModel extends AppBaseModel, DbModel extends DocumentData> {
  // References
  readonly ref: ReturnType<typeof buildTools<AppModel, DbModel>>[`ref`]
  readonly s: ReturnType<typeof buildTools<AppModel, DbModel>>[`s`]
  // Mutations
  readonly insert: ReturnType<typeof buildTools<AppModel, DbModel>>[`insert`]
  readonly insertOrSet: ReturnType<typeof buildTools<AppModel, DbModel>>[`insertOrSet`]
  readonly update: ReturnType<typeof buildTools<AppModel, DbModel>>[`update`]
  readonly set: ReturnType<typeof buildTools<AppModel, DbModel>>[`set`]
  readonly setPartial: ReturnType<typeof buildTools<AppModel, DbModel>>[`setPartial`]
  // Queries
  readonly get: ReturnType<typeof buildTools<AppModel, DbModel>>[`get`]
  readonly getDataOrDefault: ReturnType<typeof buildTools<AppModel, DbModel>>[`getDataOrDefault`]
}
