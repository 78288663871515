import { createFileRoute } from '@tanstack/react-router';
// import { UserViewFull } from '../../../components/UserViewFull';

const UserRouteComponent = () => {
  // const { userId } = Route.useParams();
  return `user view`;
  // return <UserViewFull userId={userId} />;
};

export const Route = createFileRoute(`/_normal-layout/users/$userId/view`)({
  component: UserRouteComponent,
});
