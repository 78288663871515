import { useState, useEffect } from 'react';
import { onSnapshot, query } from 'firebase/firestore';
import { db, Job } from '../db';
import { Link } from '@tanstack/react-router';
import { TsrNavButton } from './TsrNavLink';

const JobList = () => {
  const [jobs, setJobs] = useState<Job[]>([]);

  useEffect(() => {
    const jobsQuery = query(db.job.s());
    return onSnapshot(jobsQuery, snap => setJobs(snap.docs.map(doc => doc.data())));
  }, []);

  return (
    <div>
      <h1>Jobs</h1>
      <ul>
        {jobs.map(job => (
          <li key={job.title}>
            <h2>
              <Link to="/jobs/$jobId/view" params={{ jobId: job.id }}>
                {job.title} - {job.hours} {job.createdAt.format(`YYYY`)}
              </Link>
            </h2>
          </li>
        ))}
      </ul>
      <TsrNavButton to="/jobs/new">New Job</TsrNavButton>
    </div>
  );
};

export default JobList;
