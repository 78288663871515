import { z } from 'zod';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { appBaseFieldsToDb, baseModelSchemaParts, DeepImmutableExceptDates, buildTools } from './common';
import { isPermissionId, PermissionId } from '../permissions';

export const tenantIds = [
  `adams-concrete`,
  `rural-septic`,
  `acdc-welding`,
] as const;
export type TenantId = typeof tenantIds[number];

const paths = {
  tenants: `tenants`,
};

const schema = z.object({
  ...baseModelSchemaParts,
  title: z.string(),
  roles: z.array(z.object({
    id: z.string(),
    title: z.string(),
    description: z.string().optional(),
    permissionIds: z.array(z.custom<PermissionId>(isPermissionId)).readonly(),
  })).readonly(),
});

type DbModel = z.input<typeof schema>;
type AppModel = DeepImmutableExceptDates<z.output<typeof schema>>;

export type Role = AppModel[`roles`][number];

const converter = {
  toFirestore: (data: AppModel): DbModel => ({ ...data, ...appBaseFieldsToDb(data) }),
  fromFirestore: (snap: QueryDocumentSnapshot): AppModel => schema.parse({ ...snap.data(), id: snap.id }),
};

export type { AppModel as Tenant, DbModel as TenantDbModel };

export const tenantModelTools = {
  ...buildTools<AppModel, DbModel>(converter, paths.tenants, { isCrossTenant: true }),
  schema,
} as const;
