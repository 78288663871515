import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { faCog, faHome, faWrench, faUser, faUserTie, faUsers, faAddressCard, faRocket, faCircleHalfStroke, faRightToBracket, faRightFromBracket, faPlus, faStopwatch, faCalendarDays, faChartSimple, faHelmetSafety, faXmark, faCheckCircle, faPencil } from '@fortawesome/free-solid-svg-icons';
import { rem } from '@mantine/core';

type IconProps = Omit<FontAwesomeIconProps, `icon`> & {
  remSize?: number
};

const spreadProps = (props: IconProps) => {
  const { remSize, ...rest } = props;
  return {
    style: {
      ...(remSize === undefined ? undefined : { width: rem(remSize), height: rem(remSize) }),
      ...props.style,
    },
    ...rest,
  };
};

export const SettingsIcon = (props: IconProps) => <FontAwesomeIcon icon={faCog} {...spreadProps(props)} />;
export const HomeIcon = (props: IconProps) => <FontAwesomeIcon icon={faHome} {...spreadProps(props)} />;
export const JobIcon = (props: IconProps) => <FontAwesomeIcon icon={faWrench} {...spreadProps(props)} />;
export const UserIcon = (props: IconProps) => <FontAwesomeIcon icon={faUser} {...spreadProps(props)} />;
export const UsersIcon = (props: IconProps) => <FontAwesomeIcon icon={faUsers} {...spreadProps(props)} />;
export const CustomersIcon = (props: IconProps) => <FontAwesomeIcon icon={faUserTie} {...spreadProps(props)} />;
export const ProfileIcon = (props: IconProps) => <FontAwesomeIcon icon={faAddressCard} {...spreadProps(props)} />;
export const LogoutIcon = (props: IconProps) => <FontAwesomeIcon icon={faRightFromBracket} {...spreadProps(props)} />;
export const TimeLogIcon = (props: IconProps) => <FontAwesomeIcon icon={faStopwatch} {...spreadProps(props)} />;
export const CalendarIcon = (props: IconProps) => <FontAwesomeIcon icon={faCalendarDays} {...spreadProps(props)} />;
export const ReportsIcon = (props: IconProps) => <FontAwesomeIcon icon={faChartSimple} {...spreadProps(props)} />;
export const WorkerIcon = (props: IconProps) => <FontAwesomeIcon icon={faHelmetSafety} {...spreadProps(props)} />;
export const CancelIcon = (props: IconProps) => <FontAwesomeIcon icon={faXmark} {...spreadProps(props)} />;
export const CheckIcon = (props: IconProps) => <FontAwesomeIcon icon={faCheckCircle} {...spreadProps(props)} />;
export const EditIcon = (props: IconProps) => <FontAwesomeIcon icon={faPencil} {...spreadProps(props)} />;
export const TenantIcon = (props: IconProps) => <FontAwesomeIcon icon={faRocket} {...spreadProps(props)} />;
export const AddIcon = (props: IconProps) => <FontAwesomeIcon icon={faPlus} {...spreadProps(props)} />;
export const LightDarkIcon = (props: IconProps) => <FontAwesomeIcon icon={faCircleHalfStroke} {...spreadProps(props)} />;
export const LoginIcon = (props: IconProps) => <FontAwesomeIcon icon={faRightToBracket} {...spreadProps(props)} />;
