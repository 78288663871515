import { signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from '../firebase';
import { Anchor, Paper, Container, Group, Button, Image } from '@mantine/core';
import slickLogoPortrait from '../assets/logo-portrait-blue-orange.png';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormSubmitHandler, useForm } from 'react-hook-form';
import { TextInput, PasswordInput } from 'react-hook-form-mantine';
import { z } from 'zod';
import { useNavigate } from '@tanstack/react-router';
import { alpha } from '@mantine/core';

const schema = z.object({
  email: z.string().email(),
  password: z.string().min(6),
});
type Schema = z.output<typeof schema>;

export const Login = () => {
  const navigate = useNavigate();
  const { control } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: { email: ``, password: `` },
  });

  const onSubmit: FormSubmitHandler<Schema> = (payload) => {
    signInWithEmailAndPassword(firebaseAuth, payload.data.email, payload.data.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        void navigate({ to: `/` });
      })
      .catch((error: unknown) => {
        console.error(error);
      });
  };

  return (
    <Container w={600} maw="calc(100% - 40px)" m={20} p={0}>
      <Group justify="center">
        <Image src={slickLogoPortrait} w={240} />
      </Group>

      <Paper withBorder shadow="md" p={20} pt={10} mt={30} radius="md" bg={alpha(`var(--mantine-color-body)`, 0.8)}>
        <Form control={control} onSubmit={onSubmit}>
          <TextInput name="email" label="Email" required control={control} size="xl" />
          <PasswordInput name="password" label="Password" required mt="md" control={control} size="xl" />
          <Group justify="flex-end" mt="lg">
            <Anchor component="button" size="sm">
              Forgot password?
            </Anchor>
          </Group>
          <Button fullWidth mt="xl" type="submit">
            Sign in
          </Button>
        </Form>
      </Paper>
    </Container>
  );
};
