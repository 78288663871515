import { useState, useEffect } from 'react';
import { onSnapshot, query } from 'firebase/firestore';
import { db, Tenant } from '../db';
import { TsrNavButton } from './TsrNavLink';
import { Card, Image, Text, Group, SimpleGrid, Space } from '@mantine/core';
import { PageTitle } from './Typography';
import { AddIcon } from './icons/icons';

const TenantCard = (props: { tenant: Tenant }) => {
  const { tenant } = props;

  return (
    <Card shadow="sm" padding="md" radius="md" withBorder>
      <Card.Section>
        <Image src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png" height={160} alt="Norway" />
      </Card.Section>

      <Group justify="space-between" mt="md" mb="xs">
        <Text fw={500}>{tenant.title}</Text>
      </Group>

      <TsrNavButton to={`/tenants/${tenant.id}/view`} variant="light">
        View
      </TsrNavButton>
    </Card>
  );
};

const TenantList = () => {
  const [tenants, setTenants] = useState<Tenant[]>([]);

  useEffect(() => {
    const tenantsQuery = query(db.tenant.s());
    return onSnapshot(tenantsQuery, snap => setTenants(snap.docs.map(doc => doc.data())));
  }, []);

  return (
    <div>
      <PageTitle title="Tenants" />

      <SimpleGrid cols={3}>
        {tenants.map(tenant => (
          <TenantCard key={tenant.id} tenant={tenant} />
        ))}
      </SimpleGrid>
      <Group justify="center" mt="lg">
        <TsrNavButton to="/tenants/new" color="blue" size="md">
          <AddIcon />
          <Space w="sm" />
          Add Tenant
        </TsrNavButton>
      </Group>
    </div>
  );
};

export default TenantList;
