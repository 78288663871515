import { createFileRoute } from '@tanstack/react-router';
import { JobForm } from '../../../components/forms/JobForm';

const JobEditRouteComponent = () => {
  const { jobId } = Route.useParams();
  return <JobForm jobId={jobId} />;
};

export const Route = createFileRoute(`/_normal-layout/jobs/$jobId/edit`)({
  component: JobEditRouteComponent,
});
