import { Button, ButtonProps, MenuItem, MenuItemProps, NavLink, NavLinkProps } from '@mantine/core';
import { createLink } from '@tanstack/react-router';
import { ForwardedRef, forwardRef } from 'react';

export const TsrNavLink = createLink(forwardRef((props: NavLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => <NavLink {...props} ref={ref} />));

export const TsrNavButton = createLink(forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => <Button {...props} ref={ref} />));

export const TsrActionIcon = createLink(forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => <Button {...props} ref={ref} />));

export const TsrMenuItem = createLink(forwardRef((props: MenuItemProps, ref: ForwardedRef<HTMLButtonElement>) => <MenuItem {...props} ref={ref} />));
