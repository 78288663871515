import { createTheme, CSSVariablesResolver, Menu, NavLink, rem, Select } from '@mantine/core';
import '@fontsource-variable/exo-2';

export const theme = createTheme({
  colors: {
    blue: [`#ecf9fd`, `#daeff7`, `#afdff1`, `#82cfec`, `#62c0e7`, `#51b8e5`, `#46b4e5`, `#399dcb`, `#2b8cb6`, `#0d79a1`],
    orange: [`#fff4e7`, `#f6e8d7`, `#e9cfb1`, `#dcb488`, `#d29d64`, `#cb8f4d`, `#c98740`, `#b17432`, `#9e6729`, `#8a581e`],
  },
  fontFamily: `'Exo 2 Variable', Roboto, Helvetica, Arial, sans-serif`,
  fontFamilyMonospace: `Monaco, Courier, monospace`,
  fontSizes: {
    xs: rem(14),
    sm: rem(16),
    md: rem(18),
    lg: rem(20),
    xl: rem(22),
  },
  headings: {
    fontFamily: `'Exo 2 Variable', Roboto, Helvetica, Arial, sans-serif`,
    sizes: {
      h1: { fontSize: rem(32), fontWeight: `700`, lineHeight: `1` },
      h2: { fontSize: rem(26), fontWeight: `500`, lineHeight: `1` },
      h3: { fontSize: rem(18), fontWeight: `500`, lineHeight: `1` },
    },
  },
  components: {
    Select: Select.extend({
      styles: {
        input: {
          fontSize: rem(18),
        },
        option: {
          fontSize: rem(18),
        },
      },
    }),
    NavLink: NavLink.extend({
      styles: {
        root: {
          lineHeight: 1,
        },
        label: {
          fontSize: rem(18),
          fontWeight: 500,
        },
        section: {
          fontSize: rem(24),
          fontWeight: 500,
        },
      },
    }),
    Menu: Menu.extend({
      styles: {
        item: {
          fontSize: rem(18),
          lineHeight: 1,
        },
        itemSection: {
          fontSize: rem(24),
        },
      },
    }),
    Title: {
      styles: {
        root: {
          color: `#b7925b`,
        },
      },
    },
  },
});

export const cssVariablesResolver: CSSVariablesResolver = () => ({
  variables: {},
  light: {
    '--mantine-color-text': `#444`,
    '--mantine-color-default-color': `#444`,
  },
  dark: {},
});
