import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormSubmitHandler, useForm, useWatch } from 'react-hook-form';
import { TextInput } from 'react-hook-form-mantine';
import { Button, Container, Divider, Group, Loader } from '@mantine/core';
// import { User } from '../../db';
import { showSavedNotification, showSavingErrorNotification, useFormDefaultOptions } from './utils';
import { z } from 'zod';
import { useNavigate } from '@tanstack/react-router';
import { db } from '../../db';
import { userSchema } from '../../db/user';
import { DeepImmutable } from '../../utils';

const schema = z.object({
  displayName: userSchema.shape.displayName,
  email: userSchema.shape.email,
  roleIds: userSchema.shape.roleIds,
  permissionIds: userSchema.shape.permissionIds,
});

type Schema = DeepImmutable<z.output<typeof schema>>;

interface UserFormProps {
  userId?: string
}

export const UserForm = (props: UserFormProps) => {
  const { userId } = props;
  const navigate = useNavigate();
  const isNew = userId === undefined;

  const { control, formState } = useForm<Schema>({
    ...useFormDefaultOptions,
    resolver: zodResolver(schema),
    defaultValues: () => db.user.getDataOrDefault(userId, { displayName: ``, email: ``, roleIds: {}, permissionIds: {} }),
  });

  useWatch({ control, name: [`roleIds`] });

  const onSubmit: FormSubmitHandler<Schema> = (payload) => {
    console.log(payload);
    const ref = db.user.insertOrSet(payload.data, userId);
    if (isNew) void navigate({ to: `/users/${ref.id}/view` });
    showSavedNotification();
  };

  const onError = (error: unknown) => {
    console.error(error);
    showSavingErrorNotification();
  };

  if (formState.isLoading) return <Loader size="md" color="orange" />;

  return (
    <Container maw={600}>
      <Form control={control} onSubmit={onSubmit} onError={onError}>
        <TextInput name="displayName" label="Name" control={control} />
        <TextInput name="email" label="Email" control={control} />
        <Divider mt="xl" />
        <Group mt="md" justify="flex-end">
          <Button type="submit" color="green">
            Save
          </Button>
        </Group>
      </Form>
    </Container>
  );
};
