import { useState, useEffect, useMemo } from 'react';
import { getCountFromServer, limit, onSnapshot, orderBy, query, QueryOrderByConstraint } from 'firebase/firestore';
import { db, User, UserSnapshot } from '../db';
import { TsrNavButton } from './TsrNavLink';
import { Group, Space, Container, Text } from '@mantine/core';
import { AddIcon } from './icons/icons';
import { PageTitle } from './Typography';
import { createMRTColumnHelper, MantineReactTable, MRT_ColumnFiltersState, MRT_SortingState, useMantineReactTable, type MRT_ColumnDef } from 'mantine-react-table';

const columnHelper = createMRTColumnHelper<User>();

export const UserList = () => {
  const [userSnapshots, setUserSnapshots] = useState<UserSnapshot[] | undefined>(undefined);
  const [count, setCount] = useState<number | undefined>(undefined);
  const [queryState, setQueryState] = useState<`idle` | `loading`>(`loading`);

  const users = useMemo<User[] | undefined>(() => userSnapshots?.map(snap => snap.data()), [userSnapshots]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: `displayName`, desc: false }]);
  // const [pageSize, setPageSize] = useState<number>(25);
  const pageSize = 25;

  useEffect(() => {
    setQueryState(`loading`);
    const orders: QueryOrderByConstraint[] = sorting.map(({ id, desc }) => orderBy(id, desc ? `desc` : `asc`));

    const usersQuery = query(db.user.s(), ...orders, limit(pageSize));
    const usersCountQuery = query(db.user.s(), ...orders);

    getCountFromServer(usersCountQuery).then(snap => setCount(snap.data().count)).catch((error: unknown) => console.error(error));

    return onSnapshot(usersQuery, (snap) => {
      setUserSnapshots(snap.docs);
      setQueryState(`idle`);
    });
  }, [sorting, pageSize]);

  const columns = useMemo<MRT_ColumnDef<User, User>[]>(() => ([
    columnHelper.accessor(`displayName`, { header: `Name` }),
    columnHelper.accessor(`email`, { header: `Email` }),
    columnHelper.accessor(`roleIds`, { header: `Role`, Cell: ({ cell }) => JSON.stringify(cell.getValue()), enableColumnFilter: false }),
    columnHelper.accessor(`updatedAt`, { header: `Updated`, Cell: ({ cell }) => cell.getValue().format(`MMM D, YYYY`), enableColumnFilter: false }),
    columnHelper.accessor(`createdAt`, { header: `Created`, Cell: ({ cell }) => cell.getValue().format(`MMM D, YYYY`), enableColumnFilter: false }),
    columnHelper.display({ header: `Actions`, Cell: () => `actions`, enableResizing: false }),
  ]), []);

  const table = useMantineReactTable({
    columns,
    data: users ?? [],
    enableRowSelection: true,
    getRowId: row => row.id,
    manualFiltering: true,
    manualSorting: true,
    rowCount: count ?? 0,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    enableColumnResizing: true,
    enableColumnOrdering: false,
    mantineTableProps: { withTableBorder: false, withColumnBorders: false },
    layoutMode: `grid`,
    enableDensityToggle: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enablePagination: false,
    state: {
      columnFilters,
      isLoading: queryState !== `idle`,
      showProgressBars: queryState !== `idle`,
      sorting,
      density: `xs`,
    },
    mantinePaperProps: {
      withBorder: false,
      shadow: `none`,
      mb: `none`,
    },
    enableBottomToolbar: false,
  });

  return (
    <Container maw="100%">
      <PageTitle>Users</PageTitle>
      <MantineReactTable<User> table={table} />
      <Group mt="xs" justify="flex-end">
        <Text>Showing {users?.length ?? 0}/{count}</Text>
      </Group>
      <Group mt="xl" justify="flex-end">
        <TsrNavButton to="/users/new">
          <AddIcon />
          <Space w="sm" />
          New User
        </TsrNavButton>
      </Group>
    </Container>
  );
};

export default UserList;
