import { Title, TitleProps } from '@mantine/core';

export const PageTitle = (props: TitleProps) => {
  const { children, ...rest } = props;

  return (
    <Title order={1} mb="md" ta="center" {...rest}>
      { children }
    </Title>
  );
};

export const SectionHeading = (props: TitleProps) => {
  const { children, ...rest } = props;

  return (
    <Title order={2} mt="md" mb="xs" {...rest}>
      {children }
    </Title>
  );
};
