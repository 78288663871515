import { createFileRoute } from '@tanstack/react-router';
import { TenantForm } from '../../../components/forms/TenantForm';

const NewTenantRoute = () => {
  return (
    <>
      <TenantForm />
    </>
  );
};

export const Route = createFileRoute(`/_normal-layout/tenants/new`)({
  component: NewTenantRoute,
});
