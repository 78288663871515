import { createRootRoute, Outlet } from '@tanstack/react-router';
// import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { cssVariablesResolver, theme } from '../theme';

import '@fontsource-variable/exo-2';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import 'mantine-react-table/styles.css';
import './__root.module.css';

export const Route = createRootRoute({
  component: () => (
    <>
      <MantineProvider theme={theme} defaultColorScheme="dark" cssVariablesResolver={cssVariablesResolver}>
        <ModalsProvider>
          <Notifications />
          <Outlet />
        </ModalsProvider>
      </MantineProvider>
      {/* <TanStackRouterDevtools /> */}
    </>
  ),
});
