import { Stack } from '@mantine/core';
import { HomeIcon, JobIcon, TenantIcon, UsersIcon } from './icons/icons';
import { TsrNavLink } from './TsrNavLink';

export const MainMenu = () => {
  return (
    <Stack>
      <TsrNavLink to="/" leftSection={<HomeIcon />} label="Home" />
      <TsrNavLink to="/users" leftSection={<UsersIcon />} label="Users" />
      <TsrNavLink to="/jobs" leftSection={<JobIcon />} label="Jobs" />
      <TsrNavLink to="/tenants" leftSection={<TenantIcon />} label="Tenants" />
      {/* <TsrNavLink to='/workers' leftSection={<WorkerIcon />} label='Workers' /> */}
    </Stack>
  );
};
